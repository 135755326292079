/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
        /* START SELECT 2! */
        function formatState (state) {
          if (!state.id || !$(state.element).attr('data-iconurl')) {
            return state.text;
          }
          var iconUrl = $(state.element).attr('data-iconurl');
          var baseUrl = iconUrl;
          var $state = $(
            '<span><img src="' + baseUrl + '" class="img-flag" width="22px" style="margin-right: 20px;"/> ' + state.text + '</span>'
          );
          return $state;
        };
        
        $(".js-select2").select2({
          minimumResultsForSearch: Infinity,
          templateResult: formatState
        }).on('select2:open', function(e){
          setTimeout(function(){ 
            $('.select2-dropdown').addClass('opened');
           }, 200);
        }).on('select2:closing', function(e){
          // if removed, for some examples, the Select2 will not highlight the selected element
          $('.select2-dropdown').removeClass('opened');
        });
        /* END SELECT 2! */

        /* GRAVITY FORMS */
        if ($(".ginput_container_fileupload").length > 0) {
          $('<i class="attachment"></i>').insertAfter(".ginput_container_fileupload input[type=file]");
          $(".ginput_container_fileupload i.attachment").on("click", function() {
            $(this).prev().trigger("click");
          });
        }
        /* END GRAVITY FORMS */
        
        /* popups */
        $("[data-popup]").click(function() {
          var popup_id = $(this).data("popup");

          $("#popup_"+popup_id).fadeIn();
          $('html, body').addClass("over");
        });

        $(".popup-close a").click(function() {
          $("div.popup-wrapper").fadeOut();
          $('html, body').removeClass("over");
        });

        // close opened popup when escape key is pressed
        document.addEventListener("keydown", function (event) {
          if (event.which == 27) {
            $("div.popup-wrapper div.popup-close a").trigger("click");
          }
        });

        // close opened popup when click outside of element
        $(window).click(function () {
          $("div.popup-wrapper div.popup-close a").trigger("click");
        });
        $("[data-popup], div.popup-wrapper .popup-content").click(function (event) {
          event.stopPropagation();
        });
        /* end popups */
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // Manual
    'page_template_tpl_manual': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        $(window).scroll(function () {
          if ($(window).width() > 1024) {
            if ($(window).scrollTop() >= $(".areas-wrapper").position().top) {
              $(".menu-wrapper").addClass("is-sticky");
            } else {
              $(".menu-wrapper").removeClass("is-sticky");
            }
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
