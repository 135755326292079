(function ($) {
  /* HERO BLOCK */
  if ($(".block-hero").length > 0) {
    var i = 1;
    $(".block-hero").each(function () {
      $(this).addClass("heroBlock_" + i);

      const supportsVideo = !!document.createElement("video").canPlayType;
      if (supportsVideo) {
        const video = document.querySelector("video.hero");
        if (video) {
          const videoControls = document.querySelector(
            "video.hero .video-control"
          );
          const playpause = document.querySelector(".playpause");
          //const unmute = document.querySelector(".unmute");

          // Hide the default controls
          video.controls = false;

          /*playpause.addEventListener("click", (e) => {
            //video.muted = false;
            if (video.paused || video.ended) {
              $(".block-hero h1").hide();
              $("video.hero").parent().addClass("is_playing");
              $(".video-control").stop(true).fadeOut();
              video.play();
              // $("img.sound").hide();
              // $("img.no-sound").show();
            } else {
              $(".block-hero h1").show();
              $("video.hero").parent().removeClass("is_playing");
              video.pause();
            }
          });*/

          document.querySelector(".block-hero").addEventListener("click", (e) => {
            //video.muted = false;
            if (video.paused || video.ended) {
              $(".block-hero h1").hide();
              $("video.hero").parent().addClass("is_playing");
              $(".block-hero .video-control").stop(true).fadeOut();
              video.play();
              /*$("img.sound").hide();
              $("img.no-sound").show();*/

              $(".block-hero .span_play").hide();
              $(".block-hero .span_pause").css({"display": "block"});
            } else {
              $(".block-hero h1").show();
              $("video.hero").parent().removeClass("is_playing");
              video.pause();

              $(".block-hero .span_play").css({"display": "block"});
              $(".block-hero .span_pause").hide();
            }
          });

          /*unmute.addEventListener("click", (e) => {
            if (video.muted) {
              video.muted = false;
              $("img.sound").hide();
              $("img.no-sound").show();
            } else {
              video.muted = true;
              $("img.sound").show();
              $("img.no-sound").hide();
            }
          });*/

          $("video.hero, .block-hero .video-control").mouseover(function() {
            if($("video.hero").parent().hasClass('is_playing')) {
              $(".block-hero .video-control").stop(true).fadeIn();
            }
          }).mouseout(function() {
            if($("video.hero").parent().hasClass('is_playing')) {
              $(".block-hero .video-control").stop(true).fadeOut();
            }
          });

          $("video.hero, .block-hero .video-control").on("tap", function() {
            if($("video.hero").parent().hasClass('is_playing')) {
              $(".block-hero .video-control").stop(true).fadeIn();
            } else {
              $(".block-hero .video-control").stop(true).fadeOut();
            }
          });
        }
      }

      i++;
    });
  }

  /* SLIDER BLOCK */
  if ($(".block-slider").length > 0) {
    var i = 1;
    $(".block-slider").each(function () {
      $(this).addClass("sliderBlock_" + i);
      const aboutSwiper = new Swiper(
        ".sliderBlock_" + i + " .swiper-container",
        {
          watchOverflow: true,
          pagination: {
            el: ".sliderBlock_" + i + " .swiper-pagination",

            clickable: true,
          },

          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },

          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 30,
            },

            600: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            782: {
              slidesPerView: 2,
              spaceBetween: 32,
            },
          },
        }
      );
      i++;
    });
  }

  /* VIDEO BLOCK */
  if ($(".block-video").length > 0) {
    var i = 1;
    $(".block-video").each(function () {
      $(this).addClass("videoBlock_" + i);

      const supportsVideo = !!document.createElement("video").canPlayType;
      if (supportsVideo) {
        const video = document.querySelector(".block-video video");
        if (video) {
          const videoControls = document.querySelector(
            ".block-video .video-control"
          );
          const playpause = document.querySelector(".block-video .playpause");

          // Hide the default controls
          video.controls = false;

          /*playpause.addEventListener("click", (e) => {
            if (video.paused || video.ended) {
              $(".block-video video").parent().addClass("is_playing");
              video.play();
            } else {
              $(".block-video video").parent().removeClass("is_playing");
              video.pause();
            }
          });*/

          document.querySelector(".block-video .video-wrap").addEventListener("click", (e) => {
            //video.muted = false;
            if (video.paused || video.ended) {
              $(".block-video video").parent().addClass("is_playing");
              $(".block-video .video-control").stop(true).fadeOut();
              video.play();

              $(".block-video .span_play").hide();
              $(".block-video .span_pause").css({"display": "block"});
            } else {
              $(".block-video video").parent().removeClass("is_playing");
              video.pause();

              $(".block-video .span_play").css({"display": "block"});
              $(".block-video .span_pause").hide();
            }
          });

          $(".block-video .video-wrap").mouseover(function() {
            if($(".block-video video").parent().hasClass('is_playing')) {
              $(".block-video .video-control").stop(true).fadeIn();
            }
          }).mouseout(function() {
            if($(".block-video video").parent().hasClass('is_playing')) {
              $(".block-video .video-control").stop(true).fadeOut();
            }
          });

          $(".block-video .video-wrap").on("tap", function() {
            if($(".block-video video").parent().hasClass('is_playing')) {
              $(".block-video .video-control").stop(true).fadeIn();
            } else {
              $(".block-video .video-control").stop(true).fadeOut();
            }
          });
        }
      }

      i++;
    });
  }
})(jQuery);
